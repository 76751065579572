<template>
  <div class="topic">
    <div class="color-card top">
      <div class="yellow" />
      <div class="blue" />
      <div class="orange" />
    </div>

    <img class="desc" src="@/assets/img/activity/countDown-desc.png" />

    <div class="countDown" :style="{ background: countDownConfig[index].color }">
      {{ countDownConfig[index].number }}
    </div>

    <p class="cancel" @click="cancel">取消</p>

    <div class="color-card bottom">
      <div class="yellow" />
      <div class="blue" />
      <div class="orange" />
    </div>
  </div>
</template>

<script type="application/ecmascript">
  import wxSDKMixins from "@/views/activity/mixins";

  const countDownConfig = [
    { number: 3, color: '#405ED3' },
    { number: 2, color: '#F3BD00' },
    { number: 1, color: '#FF7F44' }
  ]

  export default {
    mixins: [wxSDKMixins],
    data () {
      return {
        countDownConfig,
        timer: null, // 定时器
        index: 0
      }
    },
    methods: {
      countDown () {
        this.timer = setInterval(() => {
          if (this.index === 2) {
            this.$router.push('/activity-topic-list');
          }
          if (this.index < 2) {
            this.index++
          }
        }, 800);
      },

      // 取消
      cancel () {
        this.$router.push('/activity');
      }
    },
    mounted () {
      this.countDown()
      this.initShare()
    },
    destroyed () {
      clearInterval(this.timer)
    }
  }
</script>

<style lang="less" scoped>
  .topic {
    min-height: 100vh;
    position: relative;
    background: #F7F8FB;
    .top {
      position: absolute;
      left:50%;
      transform: translateX(-50%);
      top: 0;
    }
    .bottom {
      position: absolute;
      left:50%;
      transform: translateX(-50%);
      bottom: 0;
    }
    .color-card {
      display: flex;
      flex-flow: row;
      justify-content: center;
      .yellow {
        width: 33px;
        height: 24px;
        background: #F3BD00;
      }
      .blue {
        width: 33px;
        height: 24px;
        background: #415FD5;
        margin: 0 1px;
      }
      .orange {
        width: 33px;
        height: 24px;
        background: #FF7F44;
      }
    }

    .desc {
      height: 325px;
      margin: 50px auto;
    }

    .countDown {
      position: absolute;
      width: 101px;
      height: 101px;
      transform: translateX(-50%);
      left:50%;
      top: 400px;

      font-size: 60px;
      font-weight: 600;
      color: #F7F8FB;
      line-height: 101px;
    }

    .cancel {
      position: absolute;
      left:50%;
      transform: translateX(-50%);
      top: 540px;

      height: 25px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(42, 49, 85, 0.3);
      line-height: 25px;
    }
  }
</style>
